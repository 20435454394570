 import React, { useCallback, useEffect, useState } from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { IoIosCheckmark } from 'react-icons/io';
import { Tooltip as ReactTooltip } from 'react-tooltip'

const MMBAFAX1 = ({basket, changeInfo}) => {
  
  const [deliveryInfo, setDeliveryInfo]  = useState({
    sendType      : 'F',
    paymentMethod : 'creditCard',
    receiver      : '',
    destination   : '',
    phone         : '',
    receivePhone  : '',
    receiverFax   : '',
    purpose       : '',
    paymentAmount : "1,000",
  });

  const [selectedPurpose, setSelectedPurpose]           = useState('직접입력');
   //용도 고정
  const purposes = ['직접입력', '일반용', '회사제출용', '교육기관용', '의료기관용', '공공기관용', '금융기관용', '비자발급용'];

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isMyPhoneNumberValid, setIsMyPhoneNumberValid] = useState(true);
  const [isFaxValid, setIsFaxValid] = useState(true);

  const user        = JSON.parse(sessionStorage.getItem('userInfo'));

  useEffect(()=>{
    const isMatch = purposes.slice(1).some((purpose) => purpose === basket.purpose);

    setDeliveryInfo({
      ...deliveryInfo,
      phone: user.userTel,
      purpose: basket.purpose,
      receiverFax: basket.receiver
    });

    if(!isMatch){
      setSelectedPurpose('직접입력');
    } else {
      setSelectedPurpose(basket.purpose);
    }
  }, []);

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '').slice(0, 15);

    if (sanitizedPhoneNumber.length < 11) {
      setIsPhoneNumberValid(false);
    } else {
      setIsPhoneNumberValid(true);
    }

    setDeliveryInfo({
      ...deliveryInfo,
      receivePhone: sanitizedPhoneNumber,
    });
  };

  const handleMyPhoneChange = (e) => {
    const phoneNumber = e.target.value;
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '').slice(0, 15);

    if (sanitizedPhoneNumber.length < 11) {
      setIsMyPhoneNumberValid(false);
    } else {
      setIsMyPhoneNumberValid(true);
    }

    setDeliveryInfo({
      ...deliveryInfo,
      phone: sanitizedPhoneNumber,
    });
  };

  const handleFaxChange = (e) => {
    const faxNumber = e.target.value;
    const sanitizedFaxNumber = faxNumber.replace(/\D/g, '').slice(0, 12);

    if (sanitizedFaxNumber.length < 9) {
      setIsFaxValid(false);
    } else {
      setIsFaxValid(true);
    }

    setDeliveryInfo({
      ...deliveryInfo,
      destination: sanitizedFaxNumber,
    });
  };


 
  const handlePaymentMethodChange = (method) => {
    setDeliveryInfo(deliveryInfo => ({
      ...deliveryInfo, paymentMethod : method
    }));
  }

  const handleEffect = useCallback(() => {
    changeInfo(deliveryInfo);
  }, [changeInfo, deliveryInfo]);

  useEffect(()=> {
    handleEffect();
  }, [handleEffect]);


  
  // 제출처 유효성 처리
  const handleReceiverChange = (e) => {
    let inputValue = e.target.value;

    // 특수문자 및 '볌' 글자 제거
    inputValue = inputValue.replace(/[@#$%^&*()_+[\]{}|\\:;"'<>,.!?/~`볌]/g, '');

    // 한글 최대 11자 또는 영어 최대 22자 입력 가능
    if (/[^a-zA-Z]/.test(inputValue)) {
      inputValue = inputValue.substring(0, 11);
    } else {
      inputValue = inputValue.substring(0, 22);
    }

    // 수정된 값으로 state 업데이트
    setDeliveryInfo({
      ...deliveryInfo, 
      receiverFax: inputValue 
     });
  }

   // 직접입력 유효성 처리
   const handleCustomPurposeChange = (e) => {
    const inputValue = e.target.value;
  
    // 유효성 검사 조건 추가
    let sanitizedValue = inputValue;
  
    // 1. 특수문자 불가능
    sanitizedValue = sanitizedValue.replace(/[@#$%^&*()_+[\]{}|\\:;"'<>,.!?/~`]/g, '');
  
    // 2. '볌'글자 불가능
    sanitizedValue = sanitizedValue.replace(/볌/g, '');
  
    // 3. "%u" 글자는 2자리로 치환
    sanitizedValue = sanitizedValue.replace(/%u/g, '  ');
  
    // "%" 글자는 3으로 나눔
    sanitizedValue = sanitizedValue.replace(/%/g, '   ');
  
   // 한글 최대 자 또는 영어 최대 22자 입력 가능
   if (/[^a-zA-Z]/.test(sanitizedValue)) {
    sanitizedValue = sanitizedValue.substring(0, 8);
  } else {
    sanitizedValue = sanitizedValue.substring(0, 16);
  }

    // 수정된 값으로 state 업데이트
    setDeliveryInfo({
       ...deliveryInfo, 
       purpose: sanitizedValue 
      });
  };
  
  const handlePurposeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPurpose(selectedValue);
    if(selectedValue !== "직접입력"){
      setDeliveryInfo(deliveryInfo => ({...deliveryInfo, purpose : selectedValue}));
    } else {
      setDeliveryInfo(deliveryInfo => ({...deliveryInfo, purpose : ''}));
    }
  };

  //  수신자명 정보 유효성 처리
  const handleReceiverNameChange = (e) => {
    let inputValue = e.target.value;

    // 특수문자 및 '볌' 글자 제거
    inputValue = inputValue.replace(/[@#$%^&*()_+[\]{}|\\:;"'<>,.!?/~`볌]/g, '');

    // 한글 최대 11자 또는 영어 최대 22자 입력 가능
    if (/[^a-zA-Z]/.test(inputValue)) {
      inputValue = inputValue.substring(0, 11);
    } else {
      inputValue = inputValue.substring(0, 22);
    }

    // 수정된 값으로 state 업데이트
    setDeliveryInfo({
      ...deliveryInfo, 
      receiver: inputValue 
     });
  }

  return (
    <div className="select-view">
    <div className="select-screen" id='no-margin3'>
      <div className="section-title">
          <h2 className='foam-title-s'>증명서 결제정보</h2>
      </div>
      <div className="info-box">
          <div className="info-row">
            <div className="info-label">증명서명</div>
            <div className="info-value">{basket.jobDisplayNe}</div>
          </div>
          <div className="info-row">
            <div className="info-label">통수</div>
            <div className="info-value">1 통</div>
          </div>
          <div className="info-row">
            <div className="info-label">총 금액</div>
            <div className="info-value">{deliveryInfo.paymentAmount} 원</div>
          </div> 
          </div>
        <div className="section-title" >
        <h2 className='foam-title-s position-r' id='no-left-padding'>팩스 번호    
      <span  data-tooltip-id="tooltip2" data-tip data-for="tooltip2" id='center-txt'>
            <AiFillQuestionCircle className='question'/> </span>      
              </h2> 
         <ReactTooltip
               id="tooltip2"
               effect="solid"
               place="right"
                type="info"
//                 getContent={dataTip =>
//              <div className="info-value-detail"
// >해당 수수료는 증명서 수수료와 대행수수료 (전자생성료 포함)을 합한 금액입 니다.</div>}
           >
            
                  <div className="info-value-detail"> 
받으시는 분의 팩스 설정에 따라 팩스가 정상적으로 전송되지 않을 수 있습니다. 받으시는 분의 팩스 상태를 직접 확인해 보실 것을 권장 드립니다.
          </div> 
          </ReactTooltip>

      
        <label htmlFor="input23" className='blind'>팩스 번호 입력 </label>
          <input type="tel"
          id='input23'
           value={deliveryInfo.destination} 
           onChange={handleFaxChange} 
           style={
            isFaxValid === true ? { borderColor: '' } :
            isFaxValid === false ? { borderColor: 'red' } :
            { borderColor: '' }
          }
           className="dropdown-phone-input" 
           placeholder='팩스 번호를 (-) 없이 입력해주세요.'/>
           <p className='tip-text '  id='tip-txt'>* 지역번호 필수 입력</p>  
          {!isFaxValid && deliveryInfo.destination && (
          <p className="error-message">* 팩스 번호는 9자리 이상 입력해주세요.</p>
        )}
      </div>

        {/* 용도 입력 */}
        <div className="section-title" >
      <h2 className='foam-title-s' id='no-left-padding'>용도</h2>
      <label htmlFor="select2" className='blind'>용도입력</label>

      <select className="dropdown" value={selectedPurpose} id='select2' onChange={handlePurposeChange}>
        {purposes.map((purpose) => (
            <option key={purpose} value={purpose}>
              {purpose}
            </option>
          ))}
        </select>
        {selectedPurpose === '직접입력' && (
        <input type="text" 
        id='input133'
        value={deliveryInfo.purpose} 
        placeholder="용도 최대 국문 8자 , 영문16자 입력"
        onChange={handleCustomPurposeChange} />
      )}
      <label htmlFor="input133" className='blind'>직접 입력</label>  

      </div>
      {/* 제출처 입력*/}
      <div className="section-title" >
          <p className="foam-title-s" id='no-left-padding'>제출처</p>
          <label htmlFor="input144" className='blind'>제출처 입력</label>
          <input type="text" 
          id='input144'
          value={deliveryInfo.receiverFax} 
          onChange={handleReceiverChange}
          placeholder="제출처 최대 국문11자 , 영문22자 입력" />
        </div> 
      <div className="section-title">
      <h2 className='foam-title-s' id='no-left-padding'>보내는 사람 번호</h2>
      <label htmlFor="input24" className='blind'>결제자 번호 입력 </label>
          <input type="tel" 
          id='input24'
          style={
            isMyPhoneNumberValid === true ? { borderColor: '' } :
            isMyPhoneNumberValid === false ? { borderColor: 'red' } :
            { borderColor: '' }
          }
          value={deliveryInfo.phone} 
          onChange={handleMyPhoneChange} 
          className="phone-input"
          placeholder='휴대폰 번호를 (-) 없이 입력해주세요.'
          />
  {!isMyPhoneNumberValid && deliveryInfo.phone && (
          <p className="error-message">* 휴대폰 번호는 11자리를 입력해주세요.</p>
        )}
          <p className='tip-text '  id='tip-txt'>* 문제 발생 시 연락받을 번호 입력</p>  
   
      </div>

        
          {/* <div className="section-title" >
          <h2 className='foam-title-s' id='no-left-padding'>받는 사람 이름<span className='select-tip'> (선택)</span></h2>
          <label htmlFor="input22" className='blind'>수신자 이름 입력 </label>
          <input type="text" 
          id='input22'
          value={deliveryInfo.receiver} 
          onChange={handleReceiverNameChange} 
          className="maginB" 
          placeholder='성함을 입력해주세요.'/>

      </div>  */}
      <div className="section-title">
    <h2 className='foam-title-s' id='no-left-padding'>받는 사람 번호<span className='select-tip'> (선택)</span></h2>
      <label htmlFor="input244" className='blind'>수신자 번호 입력 </label>
          <input type="tel" 
          id='input244'
           style={
            isPhoneNumberValid === true ? { borderColor: '' } :
            isPhoneNumberValid === false ? { borderColor: 'red' } :
            { borderColor: '' }
          }
          value={deliveryInfo.receivePhone} 
          onChange={handlePhoneChange} 
          className="phone-input" 
          placeholder='휴대폰 번호를 (-) 없이 입력해주세요.'
          />
           <p className='tip-text '  id='tip-txt'>* 미 입력 시 팩스 발송 안내 메시지 전송 불가</p>  

         {!isPhoneNumberValid && deliveryInfo.receivePhone && (
          <p className="error-message">* 휴대폰 번호는 11자리를 입력해주세요.</p>
        )}
      </div>
    

      <div className="section-title">
          <h2 className='foam-title-s'>결제방식</h2>
          <div className="select-btn-container" id='marginbottom05'>
          <button
            onClick={() => handlePaymentMethodChange('creditCard')}
            className={deliveryInfo.paymentMethod === 'creditCard' ? 'select-btn pay-type active' : 'select-btn pay-type'}>
            <p className={deliveryInfo.paymentMethod === 'creditCard' ? 'select-round active' : 'select-round'}>
                  {deliveryInfo.paymentMethod === 'creditCard' && <IoIosCheckmark />}
              </p>
              <p>신용카드</p>
            </button>
        </div>
        <div className="select-btn-container">
        <button
            onClick={() => handlePaymentMethodChange('globalCard')}
            className={deliveryInfo.paymentMethod === 'globalCard' ? 'select-btn active' : 'select-btn'}>
            <p className={deliveryInfo.paymentMethod === 'globalCard' ? 'select-round active' : 'select-round'}>
                  {deliveryInfo.paymentMethod === 'globalCard' && <IoIosCheckmark />}
              </p>
              <p>해외카드</p>
            </button>
          <button
            onClick={() => handlePaymentMethodChange('phoneNumber')}
            className={deliveryInfo.paymentMethod === 'phoneNumber' ? 'select-btn active' : 'select-btn'}
          >                      <p className={deliveryInfo.paymentMethod === 'phoneNumber' ? 'select-round active' : 'select-round'}>
          {deliveryInfo.paymentMethod === 'phoneNumber' && <IoIosCheckmark />}
      </p>
      <p>휴대폰</p></button>
        </div>
        </div>
        </div>
</div>
  );
};

export default MMBAFAX1;