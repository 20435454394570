import React, { useEffect, useState } from 'react';
import { MAP_DATA } from './MapDataMember';
import MemberIdSearch from '../../components/views/Member/MemberIdSearch';
import MemberPasswordSearch from '../../components/views/Member/MemberPasswardSearch';
import TopNavBar from '../../components/common/TopNavBar';
import { useRecoilState } from 'recoil';
import { memberState } from '../../recoil/memberState';


const MemberSearch = () => {
  const [content, setMemberState] = useRecoilState(memberState);
  const now   = new Date();
  const start = new Date('2024-11-01T18:00:00+09:00'); // KST > UTC
  const end   = new Date('2024-11-02T17:00:00+09:00');   // KST > UTC
  let MAP_DATA_SEL = MAP_DATA();

  if(now >= start && now <= end) {
    MAP_DATA_SEL = [MAP_DATA_SEL[0]];
  }

  const buttonValueSetting = (e) => {
    const { name } = e.target;
    setMemberState(name);
  };

  useEffect(()=>{
    if(!content){
      setMemberState('idsearch');
    }
  }, [])

    const selectComponent = {
      idsearch: <MemberIdSearch /> ,
      passwardsearch: <MemberPasswordSearch />,
    };

    return (
        <div id ="screen">
 <TopNavBar/>
      <nav className="detail-tab-lists">
      {MAP_DATA_SEL.map((data) => {
        return (
          <button
            className={
              content === data.name ? 'detail-tab-list active' : 'detail-tab-list'
            }
            onClick={buttonValueSetting}
            name={data.name}
            key={data.id}
          >
            {data.text}
          </button>
        );
      })}
    </nav>
    {content && <div className='detail-tab-content '>{selectComponent[content]}</div>}
    {!content && <div className='detail-tab-content '>{selectComponent['idsearch']} </div>}
  </div>
);
};

export default MemberSearch;