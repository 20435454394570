import React, { useEffect, useState } from "react";
import { connectAxios } from "../../module/connectAxios";
import Loadings from "../../components/common/Loading";
import { useNavigate } from "react-router";
import OneButtonPopup from "../../components/common/Popup/OneButtonPopup";
import { getDecryptParams } from "../../module/MAES256";

const MMECPAYR = () => {
  const [popupText, setPopupText]             = useState('');
  const [showPopup, setShowPopup]             = useState(false);
  const [isLoading, setIsLoading]             = useState(true);
  const navigate                              = useNavigate();

  const params        = new URLSearchParams(window.location.search);
  const LGD_PAYKEY    = getDecryptParams(params.get("data"))['LGD_PAYKEY'];

  const user      = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan     = JSON.parse(sessionStorage.getItem('giwanInfo'));
  const payReqInfo= JSON.parse(sessionStorage.getItem('payReqInfo'));
  
  useEffect(()=> {
    if(LGD_PAYKEY === null || LGD_PAYKEY === "" || LGD_PAYKEY === "X"){
      setIsLoading(false);
      setShowPopup(true);
      setPopupText("결제 실패하였습니다."); 
    } else if(LGD_PAYKEY === "null") {
      setIsLoading(false);
      setShowPopup(true);
      setPopupText("결제 취소하였습니다."); 
    } else {
      handlePaySubmit();
    }
  }, []);

  //I-06. 증명서 발급
  async function getIssue() {
    try{
      await connectAxios.post('/issue',{
        minNo       : payReqInfo.minNo,
        giwanNo     : giwan.giwanNo,
        transCnt    : "1",
        receiver    : payReqInfo.receiver,
        purpose     : payReqInfo.purpose,
        korYN       : payReqInfo.korYN === "K" ? "Y" : "N",
        userName    : user.userName,
        userTel     : payReqInfo.buyerTel
      })
      .then((response) => {
        if(response.data.resultCode === "200"){
          navigate(`/ec-pay2`);
        } else if(response.data.resultCode === "500"){
          setIsLoading(false);
          setShowPopup(true);
          setPopupText("결제 실패하였습니다."); 
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handlePaySubmit = async () => {
    //I-07. 결제 정보 전송
    try{
      async function getCertList() {
        await connectAxios.post('/issue-pay',{
          tpid        : user.tpid,
          giwanNo     : giwan.giwanNo,
          orderNo     : payReqInfo.orderNo,
          lgdPaykey   : LGD_PAYKEY,
          buyerTel    : payReqInfo.buyerTel,
          paymethod   : payReqInfo.paymethod
        })
        .then(async (response) => {
          if(response.data.resultCode === "200"){
            await getIssue();
          }
        });
      }
      
      async function getHakjukChk() {
        //I-08. 학적여부 확인
        const response = await connectAxios.get('/hakjuk-chk', {
          params: {
            lgnQryType    : giwan.lgnQryType,
            giwanNo       : giwan.giwanNo,
            tpid          : user.tpid,
            userMail      : user.userMail,
            userId        : user.userId,
            hakbun        : user.hakbun !== undefined ? user.hakbun : user.hakbunTemp,
            userBirth     : user.userBirth,
            userName      : user.userName,
            hakwi         : user.hakwi
          }
        });
        return response.data.hakbun;
      };

      async function getHakjuk(hakbun) {
        //I-01. 학적정보 확인
        const response = await connectAxios.get('/hakjuk', {
          params: {
            lgnQryType    : giwan.lgnQryType,
            giwanNo       : giwan.giwanNo,
            hakbun        : hakbun,
            userBirth     : user.userBirth,
            userName      : user.userName,
            hakwi         : user.hakwi
          }
        });
        return response.data.hakjukInfo.ename;
      };
  
      //B-04. 증명서 전송
      async function putPayInfo(userEname) {
        const response = await connectAxios.post('/send',{
          sendType    : payReqInfo.sendType,
          lgnQryType  : giwan.lgnQryType,
          minNo       : payReqInfo.minNo,
          orderNo     : payReqInfo.orderNo,
          giwanNo     : giwan.giwanNo,
          tpid        : user.tpid,
          buyerTel    : payReqInfo.buyerTel,
          receiveTel  : payReqInfo.receiveTel,
          jobNo       : payReqInfo.jobNo,
          receiver    : payReqInfo.receiver,
          destination : payReqInfo.destination,
          lgdPaykey   : LGD_PAYKEY,
          korYN       : payReqInfo.korYN,
          ename       : userEname,
          receiverFax : payReqInfo.receiverFax,
          purpose     : payReqInfo.purpose,
          paymethod   : payReqInfo.paymethod,
        });
  
        if(response.data.resultCode === "200"){
          setIsLoading(false);
          setShowPopup(true);
          setPopupText('증명서 전송이 완료되었습니다.');
        } else if (response.data.resultCode === "201"){
          setIsLoading(false);
          setShowPopup(true);
          setPopupText('증명서 전송에 실패했습니다.');
        }
      }

      if(payReqInfo.prev === "issue"){
        await getCertList();
      } else if (payReqInfo.prev === "send"){
        const hakbun = await getHakjukChk();
        const userEname = await getHakjuk(hakbun);
        putPayInfo(userEname);
      }

    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    setShowPopup(false);
  //팝업
    if (payReqInfo.prev === "issue") {
      navigate("/main");
    } else if (payReqInfo.prev === "send") {
      navigate("/basket-list", { state : { prev : 'send' }});
    }
  };

  // 새로고침시 다시 랜더링
  useEffect(() => {
    // 브라우저 새로고침 이벤트 핸들러
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // 이 부분은 브라우저마다 다르게 동작할 수 있습니다.
    };

    // 이벤트 핸들러 등록
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 핸들러 제거
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // setContent을 의존성 배열에 추가

  return (
    <React.Fragment>
    {isLoading && (
      <React.Fragment>
        <Loadings
        loadingTxt="증명서 결제 중입니다."
        loadingTxt1="잠시만 기다려주세요."
        />
      </React.Fragment>
    )}
    {showPopup && (
      <OneButtonPopup onClick={handleCancel} popupText={popupText} buttonText="확인"/>
      )}
    </React.Fragment>
  );
}
export default MMECPAYR;