import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useScript } from '../../hooks/useScript';
import { launchCrossPlatform } from '../../module/lgdPaySigngate.js?ver=2';
import PayIframe from '../../components/common/Modal/PayIframe';
import Loadings from '../../components/common/Loading';
import { connectAxios } from '../../module/connectAxios';


const MMECPAY3 = () => {
  const [isLoading, setIsLoading]             = useState(true);
  const { issueInfo, phone, paymentMethod, jobName, prev }   = useLocation().state || {};

  const { deliveryInfo, basket, orderNo }   = useLocation().state || {};

  const [ payReqInfo, setPayReqInfo ]       = useState({
    buyerTel    : '',
    orderNo     : '',
    minNo       : '',
    sendType    : '',
    receiveTel  : '',
    jobNo       : '',
    receiver    : '',
    destination : '',
    korYN       : '',
    receiverFax : '',
    purpose     : '',
    prev        : '',
    paymethod   : ''
  });
  
  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  let ready = useScript("https://xpay.uplus.co.kr/xpay/js/xpay_crossplatform.js");

  useEffect(()=>{
    if (ready === "ready"){
      if(prev === "issue"){
        setPayReqInfo({buyerTel    : phone,
        orderNo     : issueInfo.orderNo,
        minNo       : issueInfo.minNo,
        receiver    : issueInfo.receiver,
        korYN       : issueInfo.korYN,
        purpose     : issueInfo.purpose,
        prev        : prev,
        paymethod   : paymentMethod});
    
      } else if(prev === "send"){
        setPayReqInfo({buyerTel    : deliveryInfo.phone,
        orderNo     : orderNo,
        minNo       : basket.minNo,
        sendType    : deliveryInfo.sendType,
        receiveTel  : deliveryInfo.receivePhone,
        jobNo       : basket.jobNo,
        receiver    : deliveryInfo.receiver,
        destination : deliveryInfo.destination,
        korYN       : deliveryInfo.korYN,
        receiverFax : deliveryInfo.receiverFax,
        purpose     : deliveryInfo.purpose,
        prev        : prev,
        paymethod   : deliveryInfo.paymentMethod});
      }
    }
  }, [ready]);

  useEffect(()=> {
    if(payReqInfo.orderNo !== ''){
      sessionStorage.setItem("payReqInfo", JSON.stringify(payReqInfo));
    
      //mid 가져오기
      const getMid = async () => {
        const midInfo = await connectAxios.get("mid", 
        {params : {paymethod : payReqInfo.paymethod}});

        const {mid, mertkey} = midInfo.data;
        
        if(prev === "issue"){
          launchCrossPlatform(payReqInfo.paymethod, phone, user.userName, issueInfo.orderNo, jobName, issueInfo.totalPrice, prev, giwan.giwanName, mid, mertkey);
        } else if(prev === "send"){
          launchCrossPlatform(payReqInfo.paymethod, deliveryInfo.phone, user.userName, orderNo, basket.jobNe, deliveryInfo.paymentAmount, prev, giwan.giwanName, mid, mertkey);
        }
      }

      getMid();
    }
  }, [payReqInfo]);

  return (
    <div>
      {isLoading && (
        <React.Fragment>
          <Loadings
          loadingTxt="결제 중입니다."
          loadingTxt1="잠시만 기다려주세요."

          />
        </React.Fragment>
      )}
      <form method="post" name="LGD_PAYINFO" id="LGD_PAYINFO">
        <PayIframe/>
      </form>
    </div>
  );
};

export default MMECPAY3;